exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-us-contact-tsx": () => import("./../../../src/pages/contact-us/contact.tsx" /* webpackChunkName: "component---src-pages-contact-us-contact-tsx" */),
  "component---src-pages-education-derivatives-tsx": () => import("./../../../src/pages/education/derivatives.tsx" /* webpackChunkName: "component---src-pages-education-derivatives-tsx" */),
  "component---src-pages-education-education-posts-tsx": () => import("./../../../src/pages/education/education-posts.tsx" /* webpackChunkName: "component---src-pages-education-education-posts-tsx" */),
  "component---src-pages-education-indices-tsx": () => import("./../../../src/pages/education/indices.tsx" /* webpackChunkName: "component---src-pages-education-indices-tsx" */),
  "component---src-pages-education-operations-tsx": () => import("./../../../src/pages/education/operations.tsx" /* webpackChunkName: "component---src-pages-education-operations-tsx" */),
  "component---src-pages-governance-documentation-tsx": () => import("./../../../src/pages/governance/documentation.tsx" /* webpackChunkName: "component---src-pages-governance-documentation-tsx" */),
  "component---src-pages-governance-index-facts-em-ev-index-tsx": () => import("./../../../src/pages/governance/index-facts/em-ev-index.tsx" /* webpackChunkName: "component---src-pages-governance-index-facts-em-ev-index-tsx" */),
  "component---src-pages-governance-index-facts-index-tsx": () => import("./../../../src/pages/governance/index-facts/index.tsx" /* webpackChunkName: "component---src-pages-governance-index-facts-index-tsx" */),
  "component---src-pages-in-the-press-blog-tsx": () => import("./../../../src/pages/in-the-press/blog.tsx" /* webpackChunkName: "component---src-pages-in-the-press-blog-tsx" */),
  "component---src-pages-in-the-press-press-releases-tsx": () => import("./../../../src/pages/in-the-press/press-releases.tsx" /* webpackChunkName: "component---src-pages-in-the-press-press-releases-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-clients-auto-insurers-tsx": () => import("./../../../src/pages/our-clients/auto-insurers.tsx" /* webpackChunkName: "component---src-pages-our-clients-auto-insurers-tsx" */),
  "component---src-pages-our-clients-automotive-manufacturers-tsx": () => import("./../../../src/pages/our-clients/automotive-manufacturers.tsx" /* webpackChunkName: "component---src-pages-our-clients-automotive-manufacturers-tsx" */),
  "component---src-pages-our-clients-financial-firms-tsx": () => import("./../../../src/pages/our-clients/financial-firms.tsx" /* webpackChunkName: "component---src-pages-our-clients-financial-firms-tsx" */),
  "component---src-pages-our-clients-rental-car-companies-tsx": () => import("./../../../src/pages/our-clients/rental-car-companies.tsx" /* webpackChunkName: "component---src-pages-our-clients-rental-car-companies-tsx" */),
  "component---src-pages-our-clients-subprime-lenders-tsx": () => import("./../../../src/pages/our-clients/subprime-lenders.tsx" /* webpackChunkName: "component---src-pages-our-clients-subprime-lenders-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-solutions-derivatives-tsx": () => import("./../../../src/pages/solutions/derivatives.tsx" /* webpackChunkName: "component---src-pages-solutions-derivatives-tsx" */),
  "component---src-pages-solutions-indices-tsx": () => import("./../../../src/pages/solutions/indices.tsx" /* webpackChunkName: "component---src-pages-solutions-indices-tsx" */),
  "component---src-pages-solutions-on-the-horizon-tsx": () => import("./../../../src/pages/solutions/on-the-horizon.tsx" /* webpackChunkName: "component---src-pages-solutions-on-the-horizon-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-pages-typegen-tsx": () => import("./../../../src/pages/typegen.tsx" /* webpackChunkName: "component---src-pages-typegen-tsx" */),
  "component---src-pages-who-we-are-our-board-tsx": () => import("./../../../src/pages/who-we-are/our-board.tsx" /* webpackChunkName: "component---src-pages-who-we-are-our-board-tsx" */),
  "component---src-pages-who-we-are-our-mission-tsx": () => import("./../../../src/pages/who-we-are/our-mission.tsx" /* webpackChunkName: "component---src-pages-who-we-are-our-mission-tsx" */),
  "component---src-pages-who-we-are-our-team-tsx": () => import("./../../../src/pages/who-we-are/our-team.tsx" /* webpackChunkName: "component---src-pages-who-we-are-our-team-tsx" */),
  "component---src-templates-person-tsx": () => import("./../../../src/templates/person.tsx" /* webpackChunkName: "component---src-templates-person-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

